const inputElement = document.getElementById("input");

inputElement.onchange = function (event) {
  const fileList = inputElement.files;
  const file = fileList[0];

  const config = {
    delimiter: "", // auto-detect
    newline: "", // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: function (results, file) {
      convertToShopifyFormat(results.data);
    },
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
  };

  Papa.parse(file, config);
};

function convertToShopifyFormat(data) {
  const { convertArrayToCSV } = require("convert-array-to-csv");

  console.log(data);

  const header = [
    "Handle",
    "Title",
    "Body (HTML)",
    "Vendor",
    "Type",
    "Tags",
    "Published",
    "Option1 Name",
    "Option1 Value",
    "Option2 Name",
    "Option2 Value",
    "Option3 Name",
    "Option3 Value",
    "Variant SKU",
    "Variant Grams",
    "Variant Inventory Tracker",
    "Variant Inventory Qty",
    "Variant Inventory Policy",
    "Variant Fulfillment Service",
    "Variant Price",
    "Variant Compare at Price",
    "Variant Requires Shipping",
    "Variant Taxable",
    "Variant Barcode",
    "Image Src",
  ];

  var dataArrays = [];

  data.forEach((row) => {
    //console.log(row);

    if (row.post_title) {
      let sizes = row.customlist1.split(",");
      let colors = row.customlist2.split(",");

          let title = row.post_title;
          let content = row.post_content;
          let tags = row.category;
          let published = "TRUE";

          let image = row.image;

          if (!image.includes("trendez.co.nz")) {
            image = "https://trendez.co.nz/" + image;
          }

        
          sizes = sizes.filter((item) => {
            return item != ""
          });

          colors = colors.filter((item) => {
            return item != ""
          });

          let variantCombos = [];

          // If sizes AND colors

          if(sizes.length && colors.length) {
            sizes.forEach((size, sizeIndex) => {
              colors.forEach((color, colorIndex) => {
                variantCombos.push({size: size, color: color});
              })
            })
          }

          // If only colors

          if(colors.length && !sizes.length) {
            colors.forEach((color, index) => {
              variantCombos.push({color: color})
            })
          }


          // If only sizes

          if(sizes.length && !colors.length) {
            sizes.forEach((size, index) => {
              variantCombos.push({size: size})
            })
          }

          // No sizes OR colors

          if(!sizes.length && !colors.length) {
            console.log("no variants");
            variantCombos.push({size: "", color: ""}) 
          }

          //build rows
          variantCombos.forEach((variant, index) => {
            let newRow = [
              row.post_title.replace(/[\W_]+/g, "-"), //Handle
              title, //Title
              content, //Body
              "Trendez Ltd", //Vendor
              "", //Type
              tags, //Tags
              published, // Published
              "", //Option 1 Name
              "", //Option 1 Value
              "",
              "",
              "",
              "",
              row.SKU,
              "0",
              "",
              "0",
              "continue",
              "manual",
              row.price, // Price
              "",
              "TRUE",
              "TRUE",
              "",
              image,
            ];
  
            if (index != 0) {
              newRow[1] = "";
              newRow[2] = "";
              newRow[3] = "";
              newRow[4] = "";
              newRow[5] = "";
              newRow[6] = "";
              newRow[7] = "";
              newRow[8] = "";
              newRow[9] = "";
              newRow[10] = "";
              //newRow[13] = "";
              newRow[24] = "";
            } else {
              if (variant.size && variant.size != "") {
                newRow[7] = "Size";
              }
              if (variant.color && variant.color != "") {
                newRow[9] = "Colour";
              }
            }

            if (variant.size) {
              newRow[8] = variant.size;
            } 
            if (variant.color) {
              newRow[10] = variant.color;
            }
            dataArrays.push(newRow);
          });

    }
  });

  const csvFromArrayOfArrays = convertArrayToCSV(dataArrays, {
    header,
    separator: ",",
  });

  console.log("csvFromArrayOfArrays", csvFromArrayOfArrays);

  var hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:text/csv;charset=utf-8," + encodeURI(csvFromArrayOfArrays);
  hiddenElement.target = "_blank";
  hiddenElement.download = "Trendez.csv";
  hiddenElement.click();
}
